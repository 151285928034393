import React from 'react';

export class Draggable extends React.Component {

    state = {
        x: this.props.x || 0,
        y: this.props.y || 0
    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.ignorePositionUpdate) {
            this.setPositionToState(nextProps);
        }
    }
    setPositionToState(props) {
        this.setState({
            x: props.x || 0,
            y: props.y || 0
        });
    }

    onTouchStart = (event) => {
        event.preventDefault(); // prevent highlight

        // touch events from React do not contain touches,
        // so we cannot set lastDragPos here

        document.addEventListener('touchmove', this.onTouchMove);
        document.addEventListener('touchend', this.onTouchEnd);
    }
    onTouchEnd = () => {
        document.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('touchend', this.onTouchEnd);

        this.onDrop();
    }
    onTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling

        const {pageX, pageY} = event.touches[0];

        if (this.lastDragPos) {
            const {lastX, lastY} = this.lastDragPos;

            this.onDrag(pageX - lastX, pageY - lastY);
        }

        this.lastDragPos = {lastX: pageX, lastY: pageY};
    }

    onMouseDown = (event) => {
        const {pageX, pageY} = event;
        this.lastDragPos = {lastX: pageX, lastY: pageY};

        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
    }
    onMouseUp = () => {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);

        this.onDrop();
    }
    onMouseMove = (event) => {
        const {pageX, pageY} = event;
        const {lastX, lastY} = this.lastDragPos;

        this.onDrag(pageX - lastX, pageY - lastY);

        this.lastDragPos = {lastX: pageX, lastY: pageY};
    }
    onDrag(dx, dy) {
        const {x, y} = this.state;

        this.setState({
            x: x + dx,
            y: y + dy
        });

        if (this.props.onDrag) {
            this.props.onDrag({
                x: x + dx,
                y: y + dy
            });
        }
    }
    onDrop() {
        this.lastDragPos = false;

        if (this.props.onDrop) {
            const {x, y} = this.state;

            this.props.onDrop({x, y});
        }
    }

    render() {
        const {className, style, children} = this.props;

        const {x, y} = this.state;
        const fullStyle = {
            display: 'inline-block',
            cursor: 'pointer',
            WebkitUserSelect: 'none',

            position: 'absolute',
            left: x,
            top: y,

            ...style
        };

        return (
            <div
                style={fullStyle}
                className={className}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
                >
                {children}
            </div>
        );
    }
}

export class FlingedDraggable extends React.Component {

    state = {
        dx: 0,
        dy: 0,
        rotation: 0
    };

    componentDidMount() {
        /*eslint-disable react/no-did-mount-set-state*/
        setTimeout(() => this.setState({
            dx: this.props.toPos.x - this.props.fromPos.x,
            dy: this.props.toPos.y - this.props.fromPos.y,
            rotation: Math.random() * 6 - 3
        }), 0);
        /*eslint-enable react/no-did-mount-set-state*/
    }

    render() {
        const {className, style, children, fromPos, ignorePositionUpdate} = this.props;

        const {dx, dy, rotation} = this.state;
        const fullStyle = {
            display: 'inline-block',

            transform: `translate(-50%, -50%) translate(${dx}px, ${dy}px) rotate(${rotation}turn)`,
            transition: 'transform 500ms linear',

            ...style
        };

        return (
            <Draggable
                x={fromPos.x}
                y={fromPos.y}
                ignorePositionUpdate={ignorePositionUpdate}
                style={fullStyle}
                className={className}
                onMouseDown={this.onMouseDown}
                >
                {children}
            </Draggable>
        );
    }
}
