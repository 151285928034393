
// const suitOrder = ['clubs', 'diamonds', 'hearts', 'spades'];
const suitOrder = ['diamonds', 'clubs', 'hearts', 'spades'];
const rankOrder = [ 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 1 ];

export function compareSuit(card1, card2) {
    const so1 = suitOrder.indexOf(card1.suit);
    const so2 = suitOrder.indexOf(card2.suit);

    return so1 - so2;
}

export function compareRank(card1, card2) {
    const ro1 = rankOrder.indexOf(card1.rank);
    const ro2 = rankOrder.indexOf(card2.rank);

    return ro1 - ro2;
}

export function compareSuitFirst(card1, card2) {
    return compareSuit(card1, card2) || compareRank(card1, card2);
}

export function compareRankFirst(card1, card2) {
    return compareRank(card1, card2) || compareSuit(card1, card2);
}
