import React from 'react';
import Store from 'appState/Store';
import Message from './ChatMessage';

import './ChatMessages.scss';

export default class ChatMessages extends React.Component {

    state = {
        messages: {}
    }

    componentDidMount() {
        Store.addListener(this.matchChatRefUpdated, 'matchChatRef');
    }
    componentWillUnmount() {
        Store.removeListener(this.matchChatRefUpdated, 'matchChatRef');
    }

    matchChatRefUpdated = (next, prev) => {
        if (prev) {
            prev.off('child_added', this.messageAdded);
            prev.off('child_removed', this.messageDeleted);
        }
        if (next) {
            next.on('child_added', this.messageAdded);
            next.on('child_removed', this.messageDeleted);
        }
    }

    messageAdded = (snap) => {
        if (!snap) {
            return;
        }

        const { messages } = this.state;

        messages[snap.key] = snap.val();

        this.setState({
            messages
        });

        this.props.onMessageAdded();
    }

    messageDeleted = (snap) => {
        const { messages } = this.state;

        delete messages[snap.key];

        this.setState({
            messages
        });
    }

    deleteMessage = (key) => {
        const ref = Store.matchChatRef;

        if (!ref) {
            return;
        }

        ref.child(key).set(null);
    }

    render() {
        const { messages } = this.state;

        return (
            <div className="chat-messages">
                { Object.keys(messages).map(key =>
                    <Message
                        key={key}
                        id={key}
                        message={messages[key]}
                        onDelete={this.deleteMessage}
                        />
                )}
            </div>
        );
    }
}
