import React from 'react';
import Store from 'appState/Store';

import './DealingConfigForm.scss';

class DealEqually extends React.Component {

    state = {
        nofCardsForEach: null
    }

    componentDidMount() {
        Store.DealingConfig.addListener(this.onChange, 'nofCardsForEach');
    }
    componentWillUnmount() {
        Store.DealingConfig.removeListener(this.onChange, 'nofCardsForEach');
    }
    onChange = () => {
        this.forceUpdate()
    }

    onNofCardsForEachChange = (e) => {
        Store.DealingConfig.set({
            nofCardsForEach: e.target.value
        });
    }

    onFocus = (e) => {
        e.target.setSelectionRange(0, e.target.value.length);
    }

    render() {
        const { nofCardsForEach } = Store.DealingConfig;

        return (
            <form
                className="deal-form"
                onSubmit={this.props.onSubmit} >
                <span className="form-description">
                    Number:
                </span>
                <input
                    type="text"
                    className="form-input"
                    name="nofCardsForEach"
                    placeholder="Number of cards for each"
                    onChange={this.onNofCardsForEachChange}
                    value={nofCardsForEach}
                    autoFocus
                    onFocus={this.onFocus}
                    />
            </form>
        );
    }
}

export default DealEqually;
