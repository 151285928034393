import React from 'react';

import Store from 'appState/Store';
import SwapRefs from 'utilities/SwapRefs';

export default class MatchStatus extends React.Component {

    state = {}

    componentDidMount() {
        window.setTimeout(() => {
            Store.addListener(this.roundRefUpdated, 'currentRoundRef');
            Store.addListener(this.trickRefUpdated, 'currentTrickRef');
        }, 10);
    }
    componentWillUnmount() {
        Store.removeListener(this.roundRefUpdated, 'currentRoundRef');
        Store.removeListener(this.trickRefUpdated, 'currentTrickRef');
        Store.currentRoundRef && Store.currentRoundRef.off('value', this.roundChanged);
        Store.currentTrickRef && Store.currentTrickRef.off('value', this.trickChanged);
    }

    // Listen to store
    roundRefUpdated = (newRef, oldRef) => {
        SwapRefs(oldRef, newRef, 'value', this.roundChanged);
    }
    trickRefUpdated = (newRef, oldRef) => {
        SwapRefs(oldRef, newRef, 'value', this.trickChanged);
    }

    // Listen to firebase
    roundChanged = (snap) => {
        this.setState({
            round: snap.val()
        });
    }
    trickChanged = (snap) => {
        this.setState({
            trick: snap.val()
        });
    }

    render() {
        return (
            <div className="match-status">
                <span className="item">
                    Round: {this.state.round}
                </span>
                <span className="item">
                    Trick: {this.state.trick}
                </span>
            </div>
        );
    }
}
