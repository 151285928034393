import React from 'react';

import Store from 'appState/Store';
import * as Actions from 'actions/Actions';

import Button from 'components/button/Button';
import ViewSelector from './misc/ViewSelector';
import SeatSelector from './misc/SeatSelector';
import MatchStatus from './misc/MatchStatus';
import DealCardsModal from './dealCards/DealCardsModal';
import TrickWinnerModal from './trickWinnerModal/TrickWinnerModal';
import Chat from './chat/Chat';
import Table from './table/Table';
import Hand from './hand/Hand';

import './Match.scss';

class Match extends React.Component {

    componentDidMount() {
        Store.addListener(this.onChange, [
            'owner',
            'currentUserId',
            'showMatchSettings',
            'showDealCardsModal',
            'promptTrickWinner',
            'showChat',
            'unreadMessages',
            'renderHand',
            'renderTable'
        ]);
    }
    componentWillUnmount() {
        Store.removeListener(this.onChange, [
            'owner',
            'currentUserId',
            'showMatchSettings',
            'showDealCardsModal',
            'promptTrickWinner',
            'showChat',
            'unreadMessages',
            'renderHand',
            'renderTable'
        ]);
    }
    onChange = () => {
        this.forceUpdate()
    }

    toggleShowSettings = () => {
        Store.set({
            showMatchSettings: !Store.showMatchSettings
        });
    }

    toggleDealCardsModal = () => {
        Store.set({
            showDealCardsModal: !Store.showDealCardsModal
        });
    }

    hideTrickWinnerModal = () => {
        Store.set({
            promptTrickWinner: false
        });
    }

    toggleShowChat = () => {
        Store.set({
            showChat: !Store.showChat
        });
    }

    render() {
        const { showMatchSettings, showDealCardsModal, showChat,
            unreadMessages, promptTrickWinner, owner, currentUserId,
            renderHand, renderTable } = Store;
        const isOwner = owner === currentUserId;

        return (
            <div className="match">

                { showMatchSettings &&
                    <div className="settings">
                        <header>Settings</header>
                        <ViewSelector />
                        <SeatSelector />
                    </div>
                }

                { showDealCardsModal &&
                    <DealCardsModal onClose={this.toggleDealCardsModal} />
                }

                { (isOwner && promptTrickWinner) &&
                    <TrickWinnerModal play={promptTrickWinner} onClose={this.hideTrickWinnerModal} />
                }

                <div className="buttons">
                    <MatchStatus />

                    { isOwner &&
                        <div className="match-action-buttons">
                            <Button onClick={this.toggleDealCardsModal} display="basic">Deal Cards</Button>
                            <Button onClick={Actions.newTrick} display="basic">New Trick</Button>
                            <Button onClick={Actions.newRound} display="basic">New Round</Button>
                        </div>
                    }

                    <div className="toggler-buttons">
                        <Button
                            onClick={this.toggleShowSettings}
                            display={showMatchSettings ? 'primary' : 'subtle'}
                            >
                            Settings
                        </Button>
                        <Button
                            onClick={this.toggleShowChat}
                            display={showChat ? 'primary' : unreadMessages ? 'info' : 'subtle' }
                            >
                            Chat
                        </Button>
                    </div>
                </div>

                <Chat hidden={!showChat} />

                { renderTable && <Table /> }
                { renderHand && <Hand /> }

            </div>
        );
    }
}

export default Match;
