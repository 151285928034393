import { compareSuitFirst } from './CardCompare';

class Deck {

    generate() {
        const suits = ['spades', 'hearts', 'diamonds', 'clubs'];
        const deck = [];

        for (let s = 0; s < 4; s++) {
            for (let r = 1; r < 14; r++) {
                deck.push({
                    suit: suits[s],
                    rank: r
                });
            }
        }
        return deck;
    }

    shuffle(deck) {
        let rnd,
            tmp;

        // Shuffle in place. Each card will be selected exactly once, and put in a (possibly) new position.
        // (position: space in the deck, marked by an index)
        for (let i = 0; i < deck.length; i++) {
            // rnd: Index of randomly selected card.
            rnd = i + Math.floor( Math.random() * (deck.length - i) );
            // keep the card whose position we'll fill
            tmp = deck[i];
            // fill the position with the random card
            deck[i] = deck[rnd];
            // keep the card that lost it's position by putting it in the old position of the random card
            deck[rnd] = tmp;
        }
        return deck;
    }

    sortCards(cards) {
        cards.sort(compareSuitFirst);
        return cards;
    }

    generateAndShuffle() {
        return this.shuffle(this.generate());
    }

    draw(cards, number) {
        return cards.splice(0, number);
    }

    drawAndSort(cards, number) {
        return this.sortCards(this.draw(cards, number));
    }

    getSomeRandomSortedCards(number) { // Just for testing
        return this.sortCards(this.shuffle(this.generate()).slice(0, number));
    }
    
}

export default new Deck();
