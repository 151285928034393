import React from 'react';
import Store from 'appState/Store';

import { HoverableTimeLabel } from 'components/time-label/TimeLabel';

export default class Message extends React.Component {

    state = {
        // userId is used before userName is fetched
        userName: this.props.message.userId
    };

    componentDidMount() {
        const { userId } = this.props.message;

        Store.userNames.addListener(this.userNameUpdated, userId);
        Store.addListener(this.currentUserIdUpdated, 'currentUserId');
    }
    componentWillUnmount() {
        const { userId } = this.props.message;

        Store.userNames.removeListener(this.userNameUpdated, userId);
        Store.removeListener(this.currentUserIdUpdated, 'currentUserId');
    }

    userNameUpdated = (next) => {
        this.setState({
            userName: next
        });
    }

    currentUserIdUpdated = (next) => {
        this.setState({
            currentUserId: next
        });
    }

    deleteMessage = (e) => {
        e.preventDefault();

        this.props.onDelete(this.props.id);
    }

    render() {
        const { text, userId, timestamp } = this.props.message;
        const { userName, currentUserId } = this.state;

        return (
            <div className="message">
                <span className="user-name">
                    { userName }:
                </span>
                <span className="text">
                    {text}
                </span>
                { userId === currentUserId &&
                    <input
                        type="button"
                        className="delete-button"
                        value="&#10005;"
                        onClick={this.deleteMessage}
                        />
                }
                <HoverableTimeLabel timestamp={timestamp} classTags="time" />
            </div>
        );
    }
}