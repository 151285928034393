import React from 'react';
import Store from 'appState/Store';

import Card from 'components/card/Card';
import {FlingedDraggable} from 'components/draggable/Draggable';

export default class Play extends React.Component {

    componentDidMount() {
        this.unlisten = Store.userNames.addListener(this.onChange, this.props.play.userId);
    }
    componentWillUnmount() {
        this.unlisten();
    }
    onChange = () => {
        this.forceUpdate();
    }

    onDoubleClick = () => {
        const {owner, currentUserId} = Store;

        if (owner === currentUserId) {
            Store.set({
                promptTrickWinner: this.props.play
            });
        }
    }

    render() {
        const { play, x, y } = this.props;
        const playerName = Store.userNames[play.userId]
        const playerPosition = Store.positions[play.userId];

        return (
            <FlingedDraggable
                fromPos={playerPosition}
                toPos={{x, y}}
                ignorePositionUpdate
                >
                <Card card={play.card} playerName={(playerName || '').slice(0, 10)} onDoubleClick={this.onDoubleClick} />
            </FlingedDraggable>
        );
    }
}
