import React from 'react';
import classnames from 'classnames';

function formatTime(timestamp) {
    if (timestamp) {
        const fix = (xx) => {
            xx = '' + xx;
            if (xx.length === 1) {
                xx = '0' + xx;
            }
            return xx;
        };

        const date = new Date(timestamp);
        const hh = fix(date.getHours());
        const mm = fix(date.getMinutes());
        const ss = fix(date.getSeconds());

        return `${hh}:${mm}:${ss}`;
    }
}

export const TimeLabel = ({ preformatted, timestamp, classTags, ...events }) => (
    <span className={classnames('time-label', classTags)} {...events}>
        { preformatted || formatTime(timestamp) }
    </span>
);

export class HoverableTimeLabel extends React.Component {

    state = {
        isHovered: false,
    }

    onMouseOver = () => {
        this.setState({
            isHovered: true,
        });
    }

    onMouseOut = () => {
        this.setState({
            isHovered: false,
        });
    }

    render() {
        const { classTags } = this.props;
        const displayString = this.state.isHovered
            ? new Date(this.props.timestamp).toDateString() + ' - ' + formatTime(this.props.timestamp)
            : formatTime(this.props.timestamp)

        return (
            <TimeLabel
                preformatted={displayString}
                classTags={classTags}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
            />
        );
    }
}

export default TimeLabel;
