
export const phi = 1.618;

// Properties of card: TODO make it clearer
export const width = 100;
export const height = width * phi;
export const fontSize = Math.round(12 * width / 60);
export const roundness = width / (5 * phi);

export const cardWidth = width;
export const cardHeight = height;
export const cardCornerRoundness = roundness;

export const cardDiagonal = Math.sqrt(Math.pow(cardWidth, 2) + Math.pow(cardHeight, 2));
export const cardHalfDiagonal = cardDiagonal / 2;
