import React from 'react';
import firebase from 'firebase/app';
import Store from 'appState/Store';

import Match from 'pages/match/Match';

class FullMatchUrlView extends React.Component {

    state = {}

    componentDidMount() {
        this.paramsUpdated();
    }

    componentWillUnmount() {
        Store.set({ rules: null, matchId: null });
    }

    componentDidUpdate = () => {
        // There should be a better way to know that params updated?
        this.paramsUpdated();
    }

    paramsUpdated = () => {
        const { rules, matchId } = this.props.match.params;

        if (rules === this.rules && matchId === this.matchId) {
            return;
        }

        this.rules = rules;
        this.matchId = matchId;

        firebase.database().ref(`matches/${rules}/${matchId}`).once('value', this.onRead);
    }

    onRead = (snap) => {
        const match = snap.val();

        if (!match) {
            this.setState({
                matchNotFound: true,
                matchFound: false
            });
            return;
        }

        this.setState({
            matchFound: true,
            matchNotFound: false
        });

        const { rules } = match;
        const matchId = snap.key;

        Store.set({ rules, matchId });
    }

    render() {
        if (this.state.matchNotFound) {
            return (
                <div>
                    Cannot find match
                </div>
            );
        }
        if (this.state.matchFound) {
            return <Match/>;
        }

        return <div>Loading... Note that you must login to join or create a match.</div>;
    }

}

export default FullMatchUrlView;