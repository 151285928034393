import React from 'react';

import Store from 'appState/Store';
import { createMatch } from 'actions/Actions';
import Dropdown from 'components/dropdown/Dropdown';

import './new-match.scss';

const ruleOptions = [
    'No Rules',
    'Poker'
];

export default class NewMatch extends React.Component {

    state = {
        selectedRules: false,
        simpleMatchName: Store.simpleMatchName
    }

    onSimpleMatchNameChange = (e) => {
        this.setState({
            simpleMatchName: e.target.value
        });
    }

    selectRules = (index) => {
        const selectedRules = ruleOptions[index];

        this.setState({
            selectedRules
        });
    }

    onError = (error) => {
        console.error('Error creating match', error);
        // TODO show in GUI (set error to Store and always render a component which displays a popup on error)
    }

    createMatch = () => {
        const {selectedRules, simpleMatchName} = this.state;

        if (!selectedRules) {
            console.error('Please select rules');
            return;
        }
        if (!this.props.alreadyHasSimpleMatchName) {
            if (!simpleMatchName) {
                console.error('Please choose a simple match name');
                return;
            }
            Store.set({ simpleMatchName });
        }

        createMatch({
            simpleMatchName,
            rules: selectedRules
        }, this.onError);
    }

    render() {
        return (
            <div className="new-match">
                <div className="rules">
                    <span>Rules:</span>
                    <Dropdown
                        style={{ margin: 'auto' }}
                        options={ruleOptions}
                        onSelect={this.selectRules}
                        selectedOption={this.state.selectedRules}
                        noneSelectedText="Select rules"
                        />
                </div>

                {
                    !this.props.alreadyHasSimpleMatchName &&
                    <input
                        type="text"
                        placeholder="simple-match-name"
                        value={this.state.simpleMatchName}
                        onChange={this.onSimpleMatchNameChange}
                        />
                }

                <input
                    type="button"
                    value="Create Match"
                    onClick={this.createMatch}
                    />
            </div>
        );
    }
}
