import React from 'react';
import Store from 'appState/Store';
import * as AuthActions from 'actions/AuthActions';

import { LogoutButton, LoginForm, CreateUserForm, ButtonToggleLoginVsCreateUser, AuthFeedback } from './AuthStuff'; // TODO Multiple files
import CurrentUserData from './current-user/CurrentUserData';

import './Auth.scss';

export default class AuthBar extends React.Component {

    componentDidMount() {
        Store.Auth.addListener(this.onChange, ['loggedIn', 'renderLogin', 'renderCreateUser', 'feedback']);
    }
    componentWillUnmount() {
        Store.Auth.addListener(this.onChange, ['loggedIn', 'renderLogin', 'renderCreateUser', 'feedback']);
    }
    onChange = () => {
        this.forceUpdate();
    }

    render() {
        const {loggedIn, renderLogin, renderCreateUser, feedback} = Store.Auth;

        if (loggedIn) {
            return (
                <div className="auth-bar">
                    <CurrentUserData />
                    <LogoutButton onClick={AuthActions.logout} />
                </div>
            );
        }

        return (
            <div className="auth-bar">
                { renderLogin &&
                    <LoginForm onLogin={AuthActions.login} />
                }
                { renderCreateUser &&
                    <CreateUserForm onCreateUser={AuthActions.createUser} />
                }
                <AuthFeedback feedback={feedback} />

                <ButtonToggleLoginVsCreateUser
                    renderLogin={renderLogin}
                    renderCreateUser={renderCreateUser}
                    onClick={AuthActions.toggleLoginVsCreateUser} />
            </div>
        );
    }

}
