import React from 'react';
import Store from 'appState/Store';

import {Draggable} from 'components/draggable/Draggable';

const playerIndicatorWidth = 150;
const playerIndicatorHeight = 60;

class PlayerIndicator extends React.Component {

    state = {
        position: { x: 0, y: 0}
    }

    componentDidMount() {
        const { userId } = this.props;

        Store.positions.addListener(this.onChange, userId);
        Store.userNames.addListener(this.onChange, userId);
        Store.tricksWon.addListener(this.onChange, userId);
    }
    componentWillUnmount() {
        const { userId } = this.props;

        Store.positions.removeListener(this.onChange, userId);
        Store.userNames.removeListener(this.onChange, userId);
        Store.tricksWon.removeListener(this.onChange, userId);
    }
    onChange = () => {
        this.forceUpdate();
    }

    onDrop = (position) => {
        Store.positions.set({
            [this.props.userId]: position
        });
    }

    render() {
        const { userId } = this.props;
        const position = Store.positions[userId]
        const userName = Store.userNames[userId]
        const tricksWon = Store.tricksWon[userId]

        const brandGreen = 'rgb(46, 92, 46)';
        const brandWhite = 'rgb(238, 238, 238)';

        const style = {
            transform: 'translate(-50%, -50%)',
            backgroundColor: brandGreen,
            width: `${playerIndicatorWidth}px`,
            height: `${playerIndicatorHeight}px`,
            borderRadius: `${playerIndicatorHeight / 2}px`
        };
        const nameStyle = {
            position: 'absolute',
            left: `${playerIndicatorWidth / 2}px`,
            top: `${playerIndicatorHeight / 2}px`,
            transform: 'translate(-50%, -50%)',

            whiteSpace: 'nowrap',
            fontSize: '18px',
            color: brandWhite
        };
        const trickStyle = {
            position: 'absolute',
            left: `${playerIndicatorWidth / 2}px`,
            top: `${playerIndicatorHeight * 1.15}px`,
            transform: 'translate(-50%, -50%)',

            whiteSpace: 'nowrap',
            fontSize: '15px',
            color: brandGreen
        };

        return (
            <Draggable
                style={style}
                x={position.x}
                y={position.y}
                onDrop={this.onDrop}
                >
                <span style={nameStyle}>
                    {(userName || '').slice(0, 16)}
                </span>
                <span style={trickStyle}>
                    Tricks taken: {tricksWon || 0}
                </span>
            </Draggable>
        );
    }
}

export default class PlayerPositioner extends React.Component {

    state = {
        players: {}
    }

    componentDidMount() {
        Store.addListener(this.playersUpdated, 'players');
    }
    componentWillUnmount() {
        Store.removeListener(this.playersUpdated, 'players');
    }

    playersUpdated = () => {
        const {players} = Store;

        this.regeneratePositions(players);
        this.setState({players});
    }

    regeneratePositions = (players) => {
        Object.keys(players).forEach((userId, index) => {
            Store.positions.set({
                [userId]: this.generatePosition(index)
            });
        });
    }

    generatePosition = (index) => {
        const {tableWidth, tableHeight} = this.props;

        const left = playerIndicatorWidth * 0.6;
        const right = tableWidth - playerIndicatorWidth * 0.6;
        const midX = tableWidth / 2;

        const top = playerIndicatorHeight * 0.7;
        const bottom = tableHeight - playerIndicatorHeight * 0.9;
        const midY = tableHeight / 2;

        const baz = [
            { x: left, y: top },
            { x: left, y: bottom },
            { x: right, y: top },
            { x: right, y: bottom },
            { x: midX, y: top },
            { x: midX, y: bottom },
            { x: left, y: midY },
            { x: right, y: midY }
        ];

        return baz[index];
    }

    render() {
        const {players} = this.state;

        return (
            <div>
                {players && Object.keys(players).map(userId =>
                    <PlayerIndicator key={userId} userId={userId} />
                )}
            </div>
        );
    }
}
