import React from 'react';
import Store from 'appState/Store';
import * as Actions from 'actions/Actions';
import Deckster from 'utilities/deck/Deckster';
import Modal from 'components/modal/Modal';

import './TrickWinnerModal.scss';

import { func } from 'prop-types';

export default class TrickWinnerModal extends React.Component {

    static propTypes = {
        onClose: func.isRequired
    }

    onAccept = () => {
        Actions.assignTrickWinner(this.props.play);

        this.props.onClose();
    }

    render() {
        const { onClose, play } = this.props;

        const playerName = Store.userNames[play.userId];
        const cardString = Deckster.stringster(play.card);

        return (
            <Modal onClose={onClose} className="trick-winner-modal">
                <div className="description">
                    {'Did '}
                    <span className="highlight">
                        {playerName}
                    </span>
                        {' win with '}
                    <span className="highlight">
                        {cardString}
                    </span>
                    ?
                </div>
                <input
                    className="button-accept"
                    type="button"
                    value="Yes"
                    onClick={this.onAccept}
                    />
                <input
                    className="button-reject"
                    type="button"
                    value="No"
                    onClick={this.onClose}
                    />
            </Modal>
        );
    }
}
