import React from 'react';

import Store from 'appState/Store';
import Dropdown from 'components/dropdown/Dropdown';

export default class ViewSelector extends React.Component {

    componentDidMount() {
        Store.addListener(this.onChange, ['renderHand', 'renderTable']);
    }
    componentWillUnmount() {
        Store.removeListener(this.onChange, ['renderHand', 'renderTable']);
    }
    onChange = () => {
        this.forceUpdate();
    }

    getView() {
        if (Store.renderHand && Store.renderTable) {
            return 'both hand and table';
        } else if (Store.renderTable) {
            return 'table';
        } else if (Store.renderHand) {
            return 'hand';
        } else {
            return void 0
        }
    }

    changeView = (_, view) => {
        const renderHand = view.toLowerCase().indexOf('hand') > -1;
        const renderTable = view.toLowerCase().indexOf('table') > -1;

        Store.set({
            renderHand,
            renderTable
        });
    }

    render() {
        return (
            <div className="view-selector">
                <span>
                    {
                        this.getView()
                            ? "You are viewing"
                            : "You are not viewing the match"
                    }
                </span>

                <Dropdown
                    style={{ margin: 'auto' }}
                    noneSelectedText="Select a view"
                    selectedOption={this.getView()}
                    options={[ 'hand', 'table', 'both hand and table' ]}
                    onSelect={this.changeView}
                />
            </div>
        );
    }
}
