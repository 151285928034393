import React from 'react';

const gradientAngle = (degrees) => {
    return {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 0,
        gradientTransform: `rotate(${degrees})`
    };
};

export const svgGradientDefs = (
    <defs>
        <linearGradient id="black" {...gradientAngle(45)}>
            <stop stopColor="black" offset="45%"/>
            <stop stopColor="darkcyan" offset="50%"/>
            <stop stopColor="black" offset="55%"/>
            <stop stopColor="#122" offset="60%"/>
        </linearGradient>
        <linearGradient id="red" {...gradientAngle(60)}>
            <stop stopColor="#faa" offset="30%"/>
            <stop stopColor="#c00" offset="45%"/>
            <stop stopColor="#e20" offset="60%"/>
        </linearGradient>
    </defs>
);

const blackSvgGradientRef = 'url(#black)';
const redSvgGradientRef = 'url(#red)';

const blackGradient = `
    320
    -hsb(${9 / 16}, 1,  0):28
    -hsb(${9 / 16}, 1, .5):33
    -hsb(${9 / 16}, 0,  0):38
    -hsb(${9 / 16}, 0, .15):60
    `;

const redGradient = `
    300
    -#faa:15
    -#c00:40
    -#e20:50
    `;

class Deckster {

    rankster(rank) {
        if (rank === 1) {
            return 'A';
        } else if (rank <= 10) {
            return rank;
        } else if (rank === 11) {
            return 'J';
        } else if (rank === 12) {
            return 'Q';
        } else if (rank === 13) {
            return 'K';
        }
    }

    suitster(suit) {
        if (suit === 'spades') {
            return '♠';
        } else if (suit === 'hearts') {
            return '♥';
        } else if (suit === 'diamonds') {
            return '♦';
        } else if (suit === 'clubs') {
            return '♣';
        }
    }

    getGradientRef(suit) {
        // eslint-disable-next-line default-case
        switch (suit) {
            case 'spades':
            case 'clubs':
                return blackSvgGradientRef;

            case 'hearts':
            case 'diamonds':
                return redSvgGradientRef;
        }
    }

    colorizer(suit) {
        // eslint-disable-next-line default-case
        switch (suit) {
            case 'spades':
            case 'clubs':
                return blackGradient;

            case 'hearts':
            case 'diamonds':
                return redGradient;
        }
    }

    stringster(card) {
        return this.suitster(card.suit) +
            this.rankster(card.rank);
    }

}

export default new Deckster();
