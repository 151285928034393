import React from 'react';
import firebase from 'firebase/app';
import Store from 'appState/Store';

import Match from 'pages/match/Match';
import NewMatch from 'pages/new-match/NewMatch';

class SimpleMatchUrlView extends React.Component {

    state = {}

    componentDidMount() {
        this.paramsUpdated();
    }

    componentWillUnmount() {
        Store.set({ simpleMatchName: void 0 });
    }

    componentDidUpdate = () => {
        // There should be a better way to know that params updated?
        this.paramsUpdated();
    }

    paramsUpdated = () => {
        const { simpleMatchName } = this.props.match.params;

        if (simpleMatchName === this.simpleMatchName) {
            return;
        }

        this.simpleMatchName = simpleMatchName;
        Store.set({ simpleMatchName });

        if (this.simpleRef) {
            this.simpleRef.off('value', this.onRead);
        }

        this.simpleRef = firebase.database().ref(`simpleMatchNames/${simpleMatchName}`);
        this.simpleRef.on('value', this.onRead);
    }

    onRead = (snap) => {
        const match = snap.val();

        if (!match) {
            this.setState({
                createMatch: true,
                showMatch: false
            });
            return;
        }

        // TODO read match that it's pointing to.
        // If it's over, you can take ownership of this simpleMatchName and create a new match.

        const { rules, matchId } = match;

        Store.set({
            rules,
            matchId
        });

        this.setState({
            createMatch: false,
            showMatch: true
        });
    }

    render() {
        if (this.state.createMatch) {
            return <NewMatch alreadyHasSimpleMatchName />;
        }
        if (this.state.showMatch) {
            return <Match/>;
        }
        return <div>Loading... Note that you must login to join or create a match.</div>;
    }

}

export default SimpleMatchUrlView;