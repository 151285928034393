import React from 'react';

import Store from 'appState/Store';
import Dropdown from 'components/dropdown/Dropdown';

export default class DealModeSelector extends React.Component {

    componentDidMount() {
        Store.DealingConfig.addListener(this.onChange, 'dealMode');
    }
    componentWillUnmount() {
        Store.DealingConfig.removeListener(this.onChange, 'dealMode');
    }
    onChange = () => {
        this.forceUpdate()
    }

    selectMode = (index, option) => {
        Store.DealingConfig.set({
            dealMode: option
        });
    }

    render() {
        return (
            <div className="deal-mode-selector">
                <span>
                    Deal mode:
                </span>

                <Dropdown
                    style={{ margin: 'auto' }}
                    options={Store.DealingConfig.dealModes}
                    onSelect={this.selectMode}
                    selectedOption={Store.DealingConfig.dealMode}
                    noneSelectedText="Take a seat"
                    />
            </div>
        );
    }
}