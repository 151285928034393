import React from 'react';
import Store from 'appState/Store';

import Play from './Play';
import PlayerPositioner from './PlayerPositioner';

class Table extends React.Component {

    state = {
        plays: []
    }

    componentDidMount() {
        Store.addListener(this.tableRefUpdated, 'tableRef');
    }

    componentWillUnmount() {
        Store.removeListener(this.tableRefUpdated, 'tableRef');

        if (Store.tableRef) {
            Store.tableRef.off('value', this.setPlays);
        }
    }

    tableRefUpdated = (newRef, oldRef) => {
        if (oldRef) {
            oldRef.off('value', this.setPlays);
        }
        if (newRef) {
            newRef.on('value', this.setPlays);
        }
    }

    setPlays = (snap) => {
        this.setState({
            plays: snap.val()
        });
    }

    render() {
        const {plays} = this.state;
        const style = {
            position: 'relative',
            height: '500px',
            width: '100%',
            border: '1px dotted #a22',
            marginTop: '20px'
        };
        const tableWidth = window.innerWidth - 50;
        const tableHeight = 500;
        const rng = () => .9 + .2 * Math.random();

        return (
            <div style={style}>
                {plays && Object.keys(plays).map(key =>
                    <Play
                        key={key}
                        play={plays[key]}
                        x={(tableWidth / 2) * rng()}
                        y={(tableHeight / 2) * rng()}
                        />
                )}
                <PlayerPositioner tableWidth={tableWidth} tableHeight={tableHeight} />
            </div>
        );
    }
}

export default Table;
