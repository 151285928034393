import React from 'react';
import classnames from 'classnames';

import Store from 'appState/Store';

import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';

import './Chat.scss';

export default class Chat extends React.Component {

    scrollToBottom = () => {
        const { chatWindow } = this.refs;

        // TODO animate
        chatWindow.scrollTop = chatWindow.scrollHeight;
    }

    messageAdded = () => {
        window.setTimeout(this.scrollToBottom, 10);

        if (this.props.hidden) {
            Store.set({
                unreadMessages: true
            });
        }
    }

    componentWillUpdate(nextProps) {
        if (!nextProps.hidden) {
            Store.set({
                unreadMessages: false
            });
        }
    }

    render() {
        const { hidden } = this.props;
        const className = classnames('match-chat', { hidden });
        return (
            <div className={className} ref="chatWindow" >
                <ChatMessages onMessageAdded={this.messageAdded} />
                <ChatInput />
            </div>
        );
    }
}
