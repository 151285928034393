import firebase from 'firebase/app';

let Store;

class StoreRefUpdater {

    constructor(_Store) {
        Store = _Store;

        Store.addListener(this.updateCurrentUserRef, 'currentUserId');

        Store.addListener(this.updateMatchRef, 'matchId');
        Store.addListener(this.updateSeatRef, ['matchRef', 'currentUserId']);
        Store.addListener(this.updateRefsUnderMatch, 'matchRef');
        Store.addListener(this.updateHandRef, ['currentRound', 'currentUserId']);
        Store.addListener(this.roundUpdated, 'currentRound');
        Store.addListener(this.trickOrRoundUpdated, ['currentRound', 'currentTrick']);
    }

    updateCurrentUserRef = () => {
        const { currentUserId } = Store;

        Store.set({
            currentUserRef: firebase.database().ref(`users/${currentUserId}`)
        });
    }

    updateMatchRef = () => {
        const { rules, matchId } = Store;

        if (!rules || !matchId) {
            return;
        }

        Store.set({
            matchRef: firebase.database().ref(`matches/${rules}/${matchId}`)
        });
    }

    updateSeatRef = () => {
        const { matchRef, currentUserId } = Store;

        if (!matchRef) {
            return;
        }

        Store.set({
            seatRef: matchRef.child(`participants/${currentUserId}`)
        });
    }

    updateRefsUnderMatch = () => {
        const { matchRef } = Store;

        Store.set({
            currentRoundRef: matchRef.child('currentRound'),
            currentTrickRef: matchRef.child('currentTrick'),
            participantsRef: matchRef.child('participants'),
            ownerRef: matchRef.child('owner'),
            matchChatRef: matchRef.child('chat')
        });
    }

    updateHandRef = () => {
        const { currentRound, currentUserId } = Store;

        if (!currentRound || !currentUserId) {
            return; // TODO unset?
        }

        Store.set({
            handRef: Store.matchRef.child(`hands/${currentRound}/${currentUserId}`)
        });
    }

    roundUpdated = () => {
        const { currentRound } = Store;

        Store.set({
            currenRoundTrickWinnersRef: Store.matchRef.child(`trickWinners/${currentRound}`)
        });
    }

    trickOrRoundUpdated = () => {
        const { currentRound, currentTrick } = Store;

        if (!currentRound || !currentTrick) {
            return; // TODO unset?
        }

        Store.set({
            tableRef: Store.matchRef.child(`rounds/${currentRound}/${currentTrick}`),
            trickWinnerRef: Store.matchRef.child(`trickWinners/${currentRound}/${currentTrick}`)
        });
    }

}

export default StoreRefUpdater;
