import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

firebase.initializeApp({
    apiKey: "AIzaSyB98RE_EZ3ndMnAwstZCzGQrit9zuBHvHY",
    authDomain: "cardless.firebaseapp.com",
    databaseURL: "https://cardless.firebaseio.com",
    storageBucket: "firebase-cardless.appspot.com"
});
