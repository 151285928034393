import React from 'react';
import classnames from 'classnames';

import OptionList from 'components/option-list/OptionList';

import './Dropdown.scss';

class Dropdown extends React.Component {

    state = {
        open: false,
    }

    toggle = (e) => {
        e.preventDefault();

        const open = !this.state.open;

        this.setState({
            open
        });
    }

    select = (index) => {
        const option = this.props.options[index];

        this.props.onSelect(index, option);
        this.setState({ open: false });
    }

    render() {
        const { open } = this.state;
        const { options, selectedOption, noneSelectedText } = this.props;
        const selectedText = selectedOption || noneSelectedText || 'Select';

        // TODO Don't use anchor
        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <div className={classnames('dropdown', {open})}>
                <a
                    className="dropdown-selected-option"
                    onClick={this.toggle}
                >
                    {selectedText}
                </a>
                {
                    open &&
                    <OptionList
                        className="dropdown-option-list"
                        options={this.props.options}
                        onSelect={this.select}
                        selectedIndex={options.indexOf(selectedText)}
                    />
                }
            </div>
        );
    }
}

export default Dropdown;
