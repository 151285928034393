import firebase from 'firebase/app';
import React from 'react';
import Store from 'appState/Store';

import './ChatInput.scss';

export default class ChatInput extends React.Component {
    state = {}

    onChange = (e) => {
        this.setState({
            message: e.target.value
        });
    }

    postMesage = (e) => {
        e.preventDefault();

        const { matchChatRef, currentUserId } = Store;
        const { message } = this.state;

        if (!matchChatRef || !currentUserId) {
            return;
        }

        this.setState({ message: null });

        matchChatRef.push({
            userId: currentUserId,
            text: message,
            timestamp: firebase.database.ServerValue.TIMESTAMP
        });
    }

    render() {
        return (
            <form className="chat-input" onSubmit={this.postMesage}>
                <input type="submit" className="button" value="Send" />
                <input
                    type="text"
                    className="message"
                    placeholder="Write a message..."
                    onChange={this.onChange}
                    value={this.state.message}
                    />
            </form>
        );
    }
}
