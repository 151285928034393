import React, {Component} from 'react';
import './Button.scss';

import {node, func, oneOf, string, object} from 'prop-types';

class Button extends Component {

    static propTypes = {
        children: node.isRequired,
        onClick: func.isRequired,
        display: oneOf(['primary', 'basic', 'info', 'subtle']).isRequired,
        className: string,
        style: object
    }

    static defaultProps = {
        display: 'basic'
    }

    render() {
        const {children, onClick, display, className, style, ...other} = this.props;

        return (
            <button
                onClick={onClick}
                className={'button-component ' + (display || '') + (className || '')}
                style={style}
                {...other}
                >
                {children}
            </button>
        );
    }
}

export default Button;
