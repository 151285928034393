import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

export const LogoutButton = ({onClick}) => (
    <input
        type="submit"
        value="Log out"
        onClick={onClick}
        className="logout-button"
        />
);

export class LoginForm extends React.Component {

    handleLogin = (e) => {
        e.preventDefault();

        const email = ReactDOM.findDOMNode(this.refs.email).value.trim();
        const password = ReactDOM.findDOMNode(this.refs.password).value.trim();

        this.props.onLogin(email, password);
    }

    render() {
        return (
            <form className="login-form" onSubmit={this.handleLogin}>
                <span>Log in:</span>
                <input type="text" placeholder="email" ref="email" autoComplete="username" />
                <input type="password" placeholder="password" ref="password" autoComplete="current-password" />
                <input type="submit" value="Login" />
            </form>
        );
    }
}

export class CreateUserForm extends React.Component {

    handleCreateUser = (e) => {
        e.preventDefault();

        const email = ReactDOM.findDOMNode(this.refs.email).value.trim();
        const password = ReactDOM.findDOMNode(this.refs.password).value.trim();
        const passwordRepeat = ReactDOM.findDOMNode(this.refs.passwordRepeat).value.trim();

        this.props.onCreateUser(email, password, passwordRepeat);

        return;
    }

    render() {
        return (
            <form className="create-user-form" onSubmit={this.handleCreateUser}>
                <span>Create user:</span>
                <input type="text" placeholder="email" ref="email" autoComplete="username" />
                <input type="password" placeholder="password" ref="password" autoComplete="new-password" />
                <input type="password" placeholder="repeat password" ref="passwordRepeat" autoComplete="repeat password" />
                <input type="submit" value="Create User" />
            </form>
        );
    }
}

export const ButtonToggleLoginVsCreateUser = ({renderLogin, renderCreateUser, onClick}) => (
    <input
        type="button"
        value={renderLogin ? 'Create User' : renderCreateUser ? 'Log in with existing user' : ''}
        onClick={onClick}
        />
);

export const AuthFeedback = ({feedback}) => {
    if (!feedback) {
        return <noscript />;
    }

    const className = classnames({
        feedback: true,
        [feedback.type]: feedback.type
    });

    return (
        <span className={className}>
            {feedback.message}
        </span>
    );
};
