import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect }  from 'react-router-dom';

// Our Components
import AuthBar from 'components/auth-bar/AuthBar';
import Frontpage from 'pages/frontpage/Frontpage';
import NewMatch from 'pages/new-match/NewMatch';
import JoinMatch from 'pages/join-match/JoinMatch';
import FullMatchUrlView from 'pages/match/FullMatchUrlView';
import SimpleMatchUrlView from 'pages/match/SimpleMatchUrlView';
import TangeraTable from 'pages/tangera/TangeraTable';

import 'styles/main.scss';
import 'styles/react.scss';

const App = () => (
  <div>
    <AuthBar/>
    <Router>
      <Switch>
        <Redirect from="/frontpage" to="/" />
        <Route exact path="/" component={Frontpage}/>
        <Route exact path="/tangera" component={TangeraTable}/>
        <Route exact path="/ring-of-fire" component={TangeraTable}/>
        <Route exact path="/new-match" component={NewMatch}/>
        <Route exact path="/join-match" component={JoinMatch}/>
        <Route exact path="/matches/:rules/:matchId" component={FullMatchUrlView}/>
        <Route exact path="/:simpleMatchName" component={SimpleMatchUrlView}/>
      </Switch>
    </Router>
  </div>
)

export default App;
