import React from 'react';
import { element, bool, string } from 'prop-types';

export default class Flippable extends React.Component {

    static propTypes = {
        front: element.isRequired,
        back: element.isRequired,
        upsideDown: bool,
        translateX: string
    }
    static defaultProps = {
        upsideDown: false,
        translateX: '0'
    }

    render() {
        const {upsideDown, front, back, translateX} = this.props;

        const style = {
            width: '100px',
            height: '100px',
            perspective: '1000px'
        };

        const flipperStyle = {
            transition: 'transform 250ms linear',
            transformStyle: 'preserve-3d',

            position: 'relative',
            transform: upsideDown ? `rotateY(180deg) translateX(${translateX})` : ''
        };

        const frontAndBackStyle = {
            backfaceVisibility: 'hidden',

            position: 'absolute',
            top: '0',
            left: '0'
        };

        const frontStyle = {
            ...frontAndBackStyle,
            zIndex: '2',
            transform: 'rotateY(0deg)'
        };

        const backStyle = {
            ...frontAndBackStyle,
            transform: 'rotateY(180deg)'
        };

        return(
            <div style={style} onClick={this.props.onFlip}>
                <div style={flipperStyle}>
                    <div style={frontStyle}>
                        {front}
                    </div>
                    <div style={backStyle}>
                        {back}
                    </div>
                </div>
            </div>
        );
    }
}
