import Store from 'appState/Store';
import DeckUtil from 'utilities/deck/DeckUtil';

function dealCardsEqually() {
    const { matchRef, players, currentRound } = Store;
    const { nofCardsForEach } = Store.DealingConfig;

    if (!matchRef || !players || !currentRound) {
        return;
    }

    const deck = DeckUtil.generateAndShuffle();

    Object.keys(players).forEach(userId => {
        const cards = DeckUtil.drawAndSort(deck, nofCardsForEach);

        cards.forEach(card => card.notYetPlayed = true);

        matchRef.child(`hands/${currentRound}/${userId}`).set(cards);
    });
}

function dealCardsDifferently() {
    const { matchRef, players, currentRound } = Store;

    if (!matchRef || !players || !currentRound) {
        return;
    }

    const deck = DeckUtil.generateAndShuffle();

    Object.keys(players).forEach(userId => {
        const nofCards = Store.DealingConfig[`nofCardsFor_${userId}`];
        const cards = DeckUtil.drawAndSort(deck, nofCards);

        cards.forEach(card => card.notYetPlayed = true);

        matchRef.child(`hands/${currentRound}/${userId}`).set(cards);
    });
}

const modeToMethodMap = {
    equally: dealCardsEqually,
    differently: dealCardsDifferently
};

export function dealCards() {
    const mode = Store.DealingConfig.dealMode;
    const dealMethod = modeToMethodMap[mode];

    dealMethod();
}