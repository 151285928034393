import React from 'react';
import Store from 'appState/Store';

import Card from 'components/card/Card';
import {Draggable} from 'components/draggable/Draggable';

export default class CardOnHand extends React.Component {

    state = {}

    onDrag = (position) => {
        if (this.props.shouldPlayIfDropped(position)) {
            this.setState({
                indicatePlayOnDrop: true,
                ignorePositionUpdate: true
            });
        } else {
            this.setState({
                indicatePlayOnDrop: false,
                ignorePositionUpdate: true
            });
        }

    }

    onDrop = (position) => {
        if (!this.props.shouldPlayIfDropped(position)) {
            this.setState({
                indicatePlayOnDrop: false,
                ignorePositionUpdate: false
            });
            return;
        } else {
            this.setState({
                indicatePlayOnDrop: false,
                ignorePositionUpdate: true
            });

            Store.handRef.child(`${this.props.id}/notYetPlayed`).set(null, (err) => {
                if (!err) {
                    const play = { userId: Store.currentUserId, card: this.props.card };

                    Store.tableRef.push(play);
                }
            });
        }
    }

    getPlayAnimation() {
        if (this.props.card.notYetPlayed) {
            return;
        }

        const style = {
            opacity: 0,
            transform: 'translateY(-200px)',
            transitionProperty: 'transform opacity',
            transitionDuration: '400ms'
        };

        return style;
    }

    render() {
        const {card, x, y} = this.props;
        const {indicatePlayOnDrop, ignorePositionUpdate} = this.state;
        const style = this.getPlayAnimation();

        return (
            <Draggable x={x} y={y} onDrag={this.onDrag} onDrop={this.onDrop} style={style} ignorePositionUpdate={ignorePositionUpdate}>
                <Card card={card} highlight={indicatePlayOnDrop} />
            </Draggable>
        );
    }
}
