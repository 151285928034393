import React from 'react';
import Flippable from 'components/flippable/Flippable';
import Card, {CardBackSide} from 'components/card/Card';

const FlippableCard = ({card, upsideDown, rotation}) => {
    const style = {
        position: 'absolute',
        transform: `rotate(${rotation}turn)`
    };

    return (
        <Flippable
            duration="200ms"
            front={<Card style={style} card={card} />}
            back={<CardBackSide style={style} />}
            upsideDown={upsideDown}
            translateX="100%"
            />
    );
};

export default FlippableCard;
