import React from 'react';
import classnames from 'classnames';

import './OptionList.scss';

class OptionListItem extends React.Component {

    onClick = () => {
        this.props.onSelect(this.props.index);
    }

    render() {
        const {option} = this.props;

        const optionClasses = classnames({
            'option-list-item': true,
            'isSelected': this.props.isSelected
        });

        // TODO Don't use anchor
        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
            <a
                className={optionClasses}
                onClick={this.onClick}
                >
                {option}
            </a>
        );
    }
}

export default class OptionList extends React.Component {
    render() {
        const { options, onSelect, selectedIndex } = this.props;

        return (
            <div className="option-list">
                {
                    options.map((option, key) =>
                        <OptionListItem
                            key={key}
                            index={key}
                            option={option}
                            onSelect={onSelect}
                            isSelected={key === selectedIndex}
                            />
                    )
                }
            </div>
        );
    }
}
