import React from 'react';

import Store from 'appState/Store';
import SwapRefs from 'utilities/SwapRefs';
import Dropdown from 'components/dropdown/Dropdown';

export default class SeatSelector extends React.Component {

    seatTypes = [ 'player', 'observer' ];

    state = {
        seatTaken: void 0
    }

    componentDidMount() {
        Store.addListener(this.seatRefUpdated, 'seatRef');
    }

    componentWillUnmount() {
        // Unlisten to Store
        Store.removeListener(this.seatRefUpdated, 'seatRef');
        // Unlisten to Firebase
        if (Store.seatRef) {
            Store.seatRef.off('value', this.seatChanged);
        }
    }

    seatRefUpdated = (newRef, oldRef) => {
        SwapRefs(oldRef, newRef, 'value', this.seatChanged);
    }

    seatChanged = (snap) => {
        this.setState({
            seatTaken: snap.val()
        });
    }

    changeSeat = (seatTypeIndex) => {
        if (Store.seatRef) {
            const type = this.seatTypes[seatTypeIndex];

            Store.seatRef.set(type);
        }
    }

    render() {
        return (
            <div className="seat-selector">
                <span>
                    {
                        this.state.seatTaken
                            ? "You are participating as"
                            : "You are not participating"
                    }
                </span>

                <Dropdown
                    style={{ margin: 'auto' }}
                    options={this.seatTypes}
                    onSelect={this.changeSeat}
                    selectedOption={this.state.seatTaken}
                    noneSelectedText="Take a seat"
                />
            </div>
        );
    }
}
