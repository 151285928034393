import React from 'react';
import classnames from 'classnames';
import './Modal.scss';
import { func } from 'prop-types';

class Modal extends React.Component {

    static propTypes = {
        onClose: func.isRequired
    }

    componentDidMount() {
        this.positionModal();
    }

    onClose = () => {
        this.refs.modalPopup.className += " fade-out";
        this.refs.modalOverlay.className += " fade-out";
        setTimeout(this.props.onClose, 200);
    }

    positionModal() {
        const modalPopupDomElement = this.refs.modalPopup;

        if (modalPopupDomElement) {
            modalPopupDomElement.style.top = window.scrollY + window.innerHeight / 8 + 'px';
        }
    }

    render() {
        const classes = classnames('modal-content', this.props.className);

        return (
            <div className="modal">
                <div className="modal-overlay" ref="modalOverlay" onClick={this.onClose}/>
                <div className={classes} ref="modalPopup">
                    <button className="modal-close-button" onClick={this.onClose} >
                        &#10005;
                    </button>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Modal;
