import firebase from 'firebase/app';
import Store from 'appState/Store';

firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
        // Not logged in
        Store.set({
            currentUserId: void 0
        });
        Store.Auth.set({
            loggedIn: false
        });
        return;
    }

    // Logged in
    const {
        // displayName, emailVerified, photoURL, isAnonymous, refreshToken, providerData,
        email, uid
    } = user;

    // Update currentUserId
    Store.set({
        currentUserId: uid
    });
    Store.Auth.set({
        loggedIn: true,
        renderLogin: true,
        renderCreateUser: false,
        feedback: null
    });

    // Create user entry if logged in with new user
    const userRef = firebase.database().ref(`users/${uid}`);

    userRef.once('value', snap => {
        if (snap.val() === null) {
            userRef.set({
                name: !email ? `u-${uid}` : email.replace(/@.*/, ''),
                created: firebase.database.ServerValue.TIMESTAMP
            });
        }
    });

});

export const logout = () => {
    const logoutSuccess = () => {
        Store.set({
            currentUserId: void 0
        });
        Store.Auth.reset();
    };
    const logoutError = (error) => {
        Store.Auth.set({
            feedback: {
                type: "error",
                message: error.message
            }
        });
    };
    firebase.auth().signOut().then(logoutSuccess, logoutError);
};

export const login = (email, password) => {
    Store.Auth.set({
        feedback: {
            type: "info",
            message: "Loggin in..."
        }
    });

    if (!password || !email) {
        Store.Auth.set({
            feedback: {
                type: "error",
                message: "Please provide email and password."
            }
        });
        return;
    }

    firebase.auth().signInWithEmailAndPassword(email, password).catch(error => {
        // Not logged in
        Store.Auth.set({
            feedback: {
                type: "error", message: error.message
            }
        });
    });
};

export const createUser = (email, password, passwordRepeat) => {

    if (!email || !password || !passwordRepeat) {
        Store.Auth.set({
            feedback: {
                type: "error",
                message: "Please provide email and password."
            }
        });
        return;
    }

    if (password !== passwordRepeat) {
        Store.Auth.set({
            feedback: {
                type: "error",
                message: "Passwords are not identical."
            }
        });
        return;
    }

    Store.Auth.set({
        feedback: {
            type: "info",
            message: "Creating user..."
        }
    });

    firebase.auth().createUserWithEmailAndPassword(email, password).catch(error => {
        // Failed to create user
        Store.Auth.set({
            feedback: {
                type: "error", message: error.message
            }
        });
    });
};

export const toggleLoginVsCreateUser = () => {
    Store.Auth.set({
        renderLogin: !Store.Auth.renderLogin,
        renderCreateUser: !Store.Auth.renderCreateUser,
        feedback: null
    });
};
