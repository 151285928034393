import React from 'react';
import Store from 'appState/Store';

import './DealingConfigForm.scss';
import './DealDifferently.scss';

class CardsForPlayerSelector extends React.Component {

    state = {}

    componentDidMount() {
        const { playerId } = this.props;

        Store.userNames.addListener(this.nameUpdated, playerId);
        Store.DealingConfig.cardsPerPlayer.addListener(this.nofCardsUpdated, playerId);
    }

    componentWillUnmount() {
        const { playerId } = this.props;

        Store.userNames.removeListener(this.nameUpdated, playerId);
        Store.DealingConfig.cardsPerPlayer.removeListener(this.nofCardsUpdated, playerId);
    }

    nameUpdated = (next) => {
        if (next.length > 10) {
            next = next.slice(0, 10);
        }
        this.setState({
            playerName: next
        });
    }

    nofCardsUpdated = (next) => {
        this.setState({
            nofCards: next
        });
    }

    changeNofCards = (e) => {
        const { playerId } = this.props;

        Store.DealingConfig.set({
            [`nofCardsFor_${playerId}`]: e.target.value
        });
    }

    onFocus = (e) => {
        e.target.setSelectionRange(0, e.target.value.length);
    }

    render() {
        const { playerId, takeFocus } = this.props;
        const { playerName, nofCards } = this.state;

        return (
            <form
                className="cards-for-player-selector"
                onSubmit={this.props.onSubmit}
                >
                <span className="form-description">
                    {playerName}:
                </span>
                <input
                    type="text"
                    className="form-input"
                    name={`nofCardsFor_${playerId}`}
                    placeholder={`Number of cards`}
                    onChange={this.changeNofCards}
                    value={nofCards}
                    autoFocus={takeFocus}
                    onFocus={this.onFocus}
                    />
            </form>
        );
    }
}

class DealDifferently extends React.Component {

    componentDidMount() {
        Store.addListener(this.onChange, 'players');
    }
    componentWillUnmount() {
        Store.addListener(this.onChange, 'players');
    }
    onChange = (next) => {
        this.forceUpdate();
    }

    onNofCardsForEachChange = (e) => {
        Store.DealingConfig.set({
            nofCardsForEach: e.target.value
        });
    }

    render() {
        const { players } = Store;
        const playerIds = Object.keys(players);
        const { onSubmit } = this.props;

        return (
            <div className="deal-form deal-differently">
                {
                    playerIds.map((playerId, index) =>
                        <CardsForPlayerSelector
                            key={playerId}
                            playerId={playerId}
                            takeFocus={index === 0}
                            onSubmit={onSubmit} />
                )}
            </div>
        );
    }
}

export default DealDifferently;
