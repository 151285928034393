import firebase from 'firebase/app';
import Store from 'appState/Store';

/***********/
/* Actions */
/***********/
export function createMatch({ rules }, onError) {

    const { simpleMatchName } = Store;

    rules = rules.replace(' ', '');

    const participants = {};

    const { currentUserId } = Store;

    if (currentUserId) {
        participants[currentUserId] = 'player';
    }

    const matchUrl = `matches/${rules}`;

    const matchRef = firebase.database().ref(matchUrl).push({
        simpleMatchName,
        owner: currentUserId || 'none',
        rules,
        participants,
        hands: {},
        rounds: {},
        currentRound: 1,
        currentTrick: 1

    }, (error) => {
        if (error) {
            console.error('There was an error creating the match');
            onError(error);
            return;
        }

        // Add entry to simpleMatchNames
        firebase.database().ref(`simpleMatchNames/${simpleMatchName}`).set({
            rules,
            matchId: matchRef.key
        }, (error) => {
            if (!error) {
                const matchUrl = `#/${simpleMatchName}`;

                if (window.location.hash !== matchUrl) {
                    window.location = matchUrl;
                }
            } else {
                const matchUrl = `/#/matches/${rules}/${matchRef.key}`;

                if (window.location.hash !== matchUrl) {
                    window.location = matchUrl;
                }
            }
        });
    });
}

export function newTrick() {
    const { currentTrickRef } = Store;

    if (currentTrickRef) {
        currentTrickRef.once('value', (snap) => {
            const number = snap.val();

            currentTrickRef.set(number + 1);
        });
    }
}

export function newRound() {
    const { currentTrickRef, currentRoundRef } = Store;

    if (currentTrickRef && currentRoundRef) {
        currentRoundRef.once('value', (snap) => {
            const number = snap.val();

            currentRoundRef.set(number + 1);
            currentTrickRef.set(1);
            Store.set({ showDealCardsModal: true });
        });
    }
}

export function assignTrickWinner(play) {
    const { trickWinnerRef } = Store;

    if (!trickWinnerRef) {
        return;
    }

    trickWinnerRef.set(play, (error) => {
        if (!error) {
            newTrick();
        }
    });
}
