import Pockito from 'pockito';
import StoreRefUpdater from 'appState/StoreRefUpdater';
import StoreFirebaseSynchronizer from 'appState/StoreFirebaseSynchronizer';

const {Listenable} = Pockito.Reactito;

// Validators
const {boolean, string, integer, parsableInteger, parsableNumber, object, oneOf, undefOr, final} = Pockito.Validators;
const falsyOr = (validate) => (value) => !value || validate(value);
const card = object;
const firebaseRef = undefOr(object);
const shape = obj => value => object(value) && Object.keys(obj).every(key => obj[key](value[key])); // TODO add to Pockito.Validators

const Store = new Listenable({
    validator: {
        showMatchSettings: boolean,
        showDealCardsModal: boolean,
        showChat: boolean,
        renderHand: boolean,
        renderTable: boolean,
        promptTrickWinner: falsyOr(card),

        currentUserId: undefOr(string),
        currentUserData: falsyOr(object),
        simpleMatchName: undefOr(string),
        rules: undefOr(string),
        matchId: undefOr(string),
        currentRound: undefOr(integer),
        currentTrick: undefOr(integer),
        owner: undefOr(string),
        unreadMessages: undefOr(boolean),

        players: undefOr(object),
        observers: undefOr(object),
        participants: undefOr(object),

        // Firebase Refs
        currentUserRef: firebaseRef,
        matchRef: firebaseRef,
        seatRef: firebaseRef,
        currentRoundRef: firebaseRef,
        currentTrickRef: firebaseRef,
        participantsRef: firebaseRef,
        ownerRef: firebaseRef,
        matchChatRef: firebaseRef,
        currenRoundTrickWinnersRef: firebaseRef,
        tableRef: firebaseRef,
        handRef: firebaseRef,
        trickWinnerRef: firebaseRef
    },
    initialState: {
        showMatchSettings: true,
        showDealCardsModal: false,
        showChat: false,
        renderHand: true,
        renderTable: true,
        promptTrickWinner: false
    },

    positions: new Listenable({
        uniValidator: (value) => value && parsableNumber(value.x) && parsableNumber(value.y)
    }),

    tricksWon: new Listenable({
        uniValidator: undefOr(parsableNumber)
    }),

    userNames: new Listenable({
        uniValidator: string
    }),

    Auth: new Listenable({
        validator: {
            loggedIn: boolean,
            renderLogin: boolean,
            renderCreateUser: boolean,
            feedback: falsyOr(shape({
                type: oneOf(['error', 'info']),
                message: string
            }))
        },
        initialState: {
            loggedIn: false,
            renderLogin: true,
            renderCreateUser: false,
            feedback: false
        }
    }),

    DealingConfig: new Listenable({
        validator: {
            dealMode: oneOf(['equally', 'differently']),
            dealModes: final,
            nofCardsForEach: parsableInteger
        },
        initialState: {
            dealMode: 'equally',
            dealModes: ['equally', 'differently'],
            nofCardsForEach: 10
        },

        cardsPerPlayer: new Listenable({
            uniValidator: parsableInteger
        })
    }),

    config: {
        'onValidationError': 'throw',
        'onUndocumentedError': 'log'
    }
});

new StoreRefUpdater(Store);
new StoreFirebaseSynchronizer(Store);

export default Store;
