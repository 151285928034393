import React from 'react';
import Modal from 'components/modal/Modal';

import Store from 'appState/Store';
import * as DealActions from './DealActions';

import DealModeSelector from './DealModeSelector';
import DealEqually from './dealingConfigForms/DealEqually';
import DealDifferently from './dealingConfigForms/DealDifferently';

import { func } from 'prop-types';

import './DealCardsModal.scss';

class DealCardsModal extends React.Component {

    static propTypes = {
        onClose: func.isRequired
    }
    modeToComponentMap = {
        equally: DealEqually,
        differently: DealDifferently
    }

    componentDidMount() {
        Store.DealingConfig.addListener(this.onChange, 'dealMode');
    }
    componentWillUnmount() {
        Store.DealingConfig.removeListener(this.onChange, 'dealMode');
    }
    onChange = () => {
        this.forceUpdate()
    }

    submit = (e) => {
        e.preventDefault();

        DealActions.dealCards();

        this.props.onClose();
    }

    render() {
        const { onClose, dealMode } = Store.DealingConfig;
        const DealForm = this.modeToComponentMap[dealMode];

        return (
            <Modal onClose={onClose} className="deal-cards-modal">
                <DealModeSelector />
                <DealForm onSubmit={this.submit} />
                <input
                    className="deal-button"
                    type="button"
                    value="Deal Cards"
                    onClick={this.submit}
                    />
            </Modal>
        );
    }
}

export default DealCardsModal;
