import React from 'react';
import Vectors from 'utilities/Vectors';
import DeckUtil from 'utilities/deck/DeckUtil';
import {cardDiagonal, cardHalfDiagonal} from 'components/card/constants';
import {Draggable} from 'components/draggable/Draggable';
import FlippableCard from 'components/card/FlippableCard';

const tableWith = 1200;
const tableHeight = 900;

const nextCardPosition = (() => {
    const center = {
        x: tableWith / 2 - cardHalfDiagonal,
        y: tableHeight / 2 - cardHalfDiagonal
    };
    let deg = 0;
    const degDiff = 2 * Math.PI / 52;

    return () => {
        const pos = {
            x: center.x + Math.sin(deg) * center.x * 0.8,
            y: center.y + Math.cos(deg) * center.y * 0.8
        };
        deg += degDiff;

        return pos;
    };
})();

const getTopZIndexDroppedCards = (() => {
    let i = 0;
    return () => i++;
})();

class TangeraPlay extends React.Component {
    state = {
        upsideDown: true
    }
    onDrop = (pos) => {
        if (Vectors.absoluteDistance(pos, this.props) < cardDiagonal) {
            return;
        }
        this.setState({
            upsideDown: false
        });
    }
    render() {
        const {card, x, y, rotation} = this.props;
        const {upsideDown} = this.state;

        const style = {
            position: 'relative',
            zIndex: upsideDown ? 10000 : getTopZIndexDroppedCards()
        };

        return (
            <div style={style} onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
                <Draggable
                    x={x}
                    y={y}
                    ignorePositionUpdate={!upsideDown}
                    onDrop={this.onDrop}
                    >
                    <FlippableCard card={card} rotation={rotation} upsideDown={upsideDown} />
                </Draggable>
            </div>
        );
    }
}

class TangeraTable extends React.Component {

    state = {
        cards: DeckUtil.generateAndShuffle().map(card => ({
            card,
            ...nextCardPosition(),
            rotation: Math.random()
        }))
    }

    render() {
        const {cards} = this.state;
        const style = {
            border: '1px dotted #a22',
            position: 'relative',
            width: `${tableWith}px`,
            height: `${tableHeight}px`,
            margin: '20px'
        };

        return (
            <div style={style} ref={this.onRef}>
                {cards.map(({card, x, y, rotation}) =>
                    <TangeraPlay
                        key={JSON.stringify(card)}
                        card={card}
                        x={x}
                        y={y}
                        rotation={rotation}
                        />
                )}
            </div>
        );
    }
}

export default TangeraTable;
