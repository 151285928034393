import React from 'react';
import Deckster, {svgGradientDefs} from 'utilities/deck/Deckster';

import { width, height, roundness, fontSize } from './constants';

const brandGreen = 'rgb(46, 92, 46)';

export default class Card extends React.Component {

    lastTap = 0
    maxDoubleTapDelay = 300

    onTouchEnd = () => {
        const now = Date.now();

        if (now - this.lastTap < this.maxDoubleTapDelay) {
            this.lastTap = 0;
            this.props.onDoubleClick && this.props.onDoubleClick();
        } else {
            this.lastTap = now;
        }
    }

    render() {
        const {card, playerName, onDoubleClick, highlight, style} = this.props;

        const rank = Deckster.rankster(card.rank);
        const suit = Deckster.suitster(card.suit);
        const gradientRef = Deckster.getGradientRef(card.suit);
        const string = suit + ' ' + rank;

        return (
            <svg width={width + 4} height={height + 4} style={style} onDoubleClick={onDoubleClick} onTouchEnd={this.onTouchEnd}>
                {svgGradientDefs}
                <rect
                    x="2"
                    y="2"
                    width={width}
                    height={height}
                    rx={roundness}
                    ry={roundness}
                    fontSize={fontSize}
                    fill="white"
                    stroke={highlight ? "lightcoral" : "black"}
                    />
                <text
                    fill={gradientRef}
                    textAnchor="start"
                    fontSize={fontSize}
                    x={2 + roundness}
                    y={2 + 2 * roundness}
                    >
                    {string}
                </text>
                <text
                    fill={gradientRef}
                    textAnchor="start"
                    fontSize={fontSize}
                    x={2 + roundness}
                    y={2 + 2 * roundness}
                    transform={`rotate(180 ${width / 2 + 2} ${height / 2 + 2})`}
                    >
                    {string}
                </text>
                <text
                    fill={brandGreen}
                    textAnchor="middle"
                    fontSize={fontSize * 0.7}
                    x={2 + width / 2}
                    y={2 + height / 2}
                    >
                    {playerName}
                </text>
            </svg>
        );
    }
}

export const CardBackSide = ({style}) => (
    <svg width={width + 4} height={height + 4} style={style}>
        <rect
            x="2"
            y="2"
            width={width}
            height={height}
            rx={roundness}
            ry={roundness}
            fontSize={fontSize}
            fill="#f66"
            stroke="black"
            />
    </svg>
);
