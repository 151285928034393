import React from 'react';

import Store from 'appState/Store';

import './CurrentUserData.scss';

class CurrentUserData extends React.Component {

    state = {}

    componentDidMount() {
        Store.addListener(this.onChange, 'currentUserData');
    }
    componentWillUnmount() {
        Store.removeListener(this.onChange, 'currentUserData');
    }
    onChange = () => {
        this.forceUpdate()
    }

    render() {
        if (!Store.currentUserData) {
            return false;
        }

        const { name } = Store.currentUserData;

        return (
            <span className="current-user-data">
                <span className="text">
                    Logged in as
                </span>
                <span className="name">
                    {name}
                </span>
            </span>
        );
    }
}

export default CurrentUserData;
