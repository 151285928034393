import firebase from 'firebase/app';
import SwapRefs from 'utilities/SwapRefs';

let Store;

class StoreFirebaseSynchronizer {

    constructor(_Store) {
        Store = _Store;

        // Store.addListener(this.matchRefUpdated, 'matchRef'); // TODO flatten firebase before we can listen to this whole shabang
        Store.addListener(this.ownerRefUpdated, 'ownerRef');
        Store.addListener(this.currentUserRefUpdated, 'currentUserRef');
        Store.addListener(this.listenForCurrentTrick, 'currentTrickRef');
        Store.addListener(this.currentRoundRefUpdated, 'currentRoundRef');
        Store.addListener(this.listenForParticipants, 'participantsRef');
        Store.addListener(this.requestParticipantNames, 'participants');
        Store.addListener(this.listenForTrickWinners, 'currenRoundTrickWinnersRef');
    }

    // matchRefUpdated = (next, prev) => {
    //     if (prev) {
    //         prev.off('value', this.updateMatchData);
    //     }
    //     if (next) {
    //         next.on('value', this.updateMatchData);
    //     }
    // }

    // updateMatchData = (snap) => {
    //     Store.set({
    //         matchData: snap.val()
    //     });
    // }

    ownerRefUpdated = (next, prev) => {
        if (prev) {
            prev.off('value', this.updateOwner);
        }
        if (next) {
            next.on('value', this.updateOwner);
        }
    }

    updateOwner = (snap) => {
        Store.set({
            owner: snap.val()
        });
    }

    currentUserRefUpdated = (next, prev) => {
        if (prev) {
            prev.off('value', this.updateCurrentUserData);
        }
        if (next) {
            next.on('value', this.updateCurrentUserData);
        }
    }

    updateCurrentUserData = (snap) => {
        Store.set({
            currentUserData: snap.val()
        });
    }

    listenForCurrentTrick = (next, prev) => {
        if (prev) {
            prev.off('value', this.updateCurrentTrick);
        }
        if (next) {
            next.on('value', this.updateCurrentTrick);
        }
    }
    updateCurrentTrick = (snap) => {
        Store.set({
            currentTrick: snap.val()
        });
    }

    currentRoundRefUpdated = (next, prev) => {
        if (prev) {
            prev.off('value', this.updateCurrentRound);
        }
        if (next) {
            next.on('value', this.updateCurrentRound);
        }
    }
    updateCurrentRound = (snap) => {
        Store.set({
            currentRound: snap.val()
        });
    }

    listenForParticipants = (newRef, oldRef) => {
        SwapRefs(oldRef, newRef, 'value', this.participantsUpdated);
    }
    participantsUpdated = (snap) => {
        const participants = snap.val() || {};

        const players = {};
        const observers = {};

        Object.keys(participants).forEach(userId => {
            const participantStatus = participants[userId];

            if (participantStatus === 'player') {
                players[userId] = true;
            } else if (participantStatus === 'observer') {
                observers[userId] = true;
            }
        });

        Store.set({
            players,
            observers,
            participants
        });
    }

    requestParticipantNames = () => {
        const { participants } = Store;

        Object.keys(participants).forEach(userId => {
            firebase.database().ref(`users/${userId}/name`).once('value', this.receiveParticipantName);
        });
    }
    receiveParticipantName = (snap) => {
        const userId = snap.ref.parent.key;
        const userName = snap.val();

        Store.userNames.set({
            [userId]: userName
        });
    }

    listenForTrickWinners = (next, prev) => {
        if (prev) {
            prev.off('value', this.trickWinnersUpdated);
        }
        if (next) {
            next.on('value', this.trickWinnersUpdated);
        }
    }

    trickWinnersUpdated = (snap) => {
        const winningPlays = snap.val();

        if (!winningPlays) {
            // No tricks won: Reset to 0 for all players
            if (Store.players) {
                const userIds = Object.keys(Store.players);

                userIds.forEach(userId => {
                    Store.tricksWon.set({
                        [userId]: 0
                    });
                });
            }
            return;
        }

        // Otherwise count and update tricks won
        const tricksWon = [];

        Object.keys(winningPlays).forEach(key => {
            const { userId } = winningPlays[key];

            tricksWon[userId] = tricksWon[userId] || 0;
            tricksWon[userId]++;
        });

        Object.keys(tricksWon).forEach(userId => {
            Store.tricksWon.set({
                [userId]: tricksWon[userId]
            });
        });
    }

}

export default StoreFirebaseSynchronizer;
