import React from 'react';
import {Link} from 'react-router-dom';

import './Frontpage.scss';

export default class Frontpage extends React.Component {
    render() {
        return (
            <div className="front-page">
                <Link to="/new-match">New Match</Link>
                <Link to="/join-match">Join Match</Link>
            </div>
        );
    }
}
