import React from 'react';
import Store from 'appState/Store';
import CardOnHand from './CardOnHand';

export default class Hand extends React.Component {

    state = {
        handId: '',
        cards: []
    }

    componentDidMount() {
        Store.addListener(this.handRefUpdated, 'handRef');
    }

    componentWillUnmount() {
        Store.removeListener(this.handRefUpdated, 'handRef');
    }

    handRefUpdated = (next, prev) => {
        if (prev) {
            prev.off('value', this.handUpdated);
        }
        if (next) {
            next.on('value', this.handUpdated);
        }
        this.setState({
            handId: !next ? '' : next.toString().split('/hands/')[1]
        });
    }

    handUpdated = (snap) => {
        this.setState({
            cards: snap.val() || []
        });
    }

    render() {
        const {cards, handId} = this.state;
        const style = {
            position: 'relative',
            height: '300px',
            width: '100%',
            border: '1px dotted #a22',
            marginTop: '20px',
            overflow: 'hidden'
        };

        return (
            <div style={style}>
                {cards.map((card, index) => 
                    <CardOnHand
                        key={handId + index}
                        id={index}
                        card={card}
                        x={30 + 55 * index}
                        y={100}
                        shouldPlayIfDropped={(position) => position.y < 50}
                        />
                )}
            </div>
        );
    }
}
